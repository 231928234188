.wrapper {
	display: flex;
	padding: 0 64px;
	height: 100vh;
	background-color: #ecf0f3;
	box-sizing: inherit;
	color: #657d95;
	overflow: hidden;

	@media only screen and (max-width: 900px) {
		padding: 0;
		height: 100%;
	}
}

.content {
	flex-direction: row;
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	box-shadow: 0 2px 16px rgba(0, 0, 0, 0.25);
	background-color: white;
	overflow: auto;
}

.contentBlocks{
  display: flex;
  flex-direction: row;
	height: 100%;
	justify-content: flex-start;
	
	@media only screen and (max-width: 900px) {
		flex-direction: column;
	}
}