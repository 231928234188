.wrapper{
  flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	
	input {
		height: 51px;
		color: #657d95;
		font-size: 16px;
		font-weight: 400;
		line-height: 21.03px;
	}
}

.label {
	text-align: left;
	display: block;
	padding: 0 0 5px 1px;
	color: #657d95;
	font-size: 16px;
	font-weight: 400;
	line-height: 21.03px;
}