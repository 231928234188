/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white; 
}

/* Handle */
::-webkit-scrollbar-thumb {
  height: 1px;
  width: 10px;
  border-radius: 3px;
  background: #488dd1; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {

}



