.card {
  border-radius: 10px;
  border: 1px solid #eff1f3;
  margin-top: 15px;
  font-size: 13px;
}

.title {
  padding: 16px;
  background-color: #f3f6fa;
  border-bottom: 1px solid #eff1f3;
}

.cardContent {
  padding: 15px;
  background-color: white;
}

.payBtn {
  background-color: #2c7dce;
  padding: 4px 7px;
  color: white;
  border-radius: 14px;
  border: none;
  outline: none;
  cursor: pointer;
}