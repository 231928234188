.modal {
  width: 100%;
  max-width: 100%;
  top: 0px;
  margin: 0;
  min-height: 100vh;
  padding-bottom: 0px;

  & > div:nth-child(2) {
    padding-top: 0px;
    height: 100vh;
  }
}

@media only screen and (min-width: 540px) {
  .modal {
    max-width: 453px;
    margin: 0 auto;
    top: 100px;

    & > div:nth-child(2) {
      padding: 20px 12px !important;
      height: auto;
    }
  }
}

.title {
  font-size: 18px;
  color: #2e3039;
}

.eripInput {
  max-width: 240px;
  margin-right: 15px;
}

.eripTitle {
  margin-top: 15px;
  font-size: 15px;
  color: #657d95;
}

.eripWrapper {
  display: flex;
  margin-top: 5px;
}

.eripSubtitle {
  margin-top: 10px;
  font-size: 15px;
  color: #657d95;
}

.fileWrapper {
  display: flex;
  margin-top: 30px;
  line-height: 1;
  align-items: center;

  & > img {
    height: 24px;
    margin-right: 5px;
  }
}
