$title: 30px;
$title-weight: 300;

$padding-top: 29px;
$padding-left: 40px;
$padding-bottom: 72px;

$border-radius: 7px;

$input-border: 1px solid #c9d1d8;
$input-height: 40px;

.title {
	font-weight: $title-weight;
	line-height: 41px;
	font-size: $title;
	padding-bottom: 25px;
	color: #2e3039;
}

.checkboxContainer {
  display: flex;
	justify-content: space-between;
	padding-bottom: 20px;

	label {
		display: flex;
		align-items: center;
	}
	@media only screen and (max-width: 540px) {
		flex-direction: column;
		}
}

.text {
	padding-left: 10px;
}

.wrapper{
	padding: $padding-top 0 72px $padding-left;
  flex-direction: column;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	height: max-content;
	min-width: 400px;

	@media only screen and (max-width: 900px) {
		padding: 20px;
		max-width: 100%;
		min-width: 100%;
		}

	input {
		border: $input-border;
		height: $input-height;
		color: #657d95;
		font-size: 16px;
		font-weight: 400;
		line-height: 21.03px;
		border-radius: $border-radius;
	}
}

.inputContainer {
	display: flex;
	width: 100%;
	padding-bottom: 20px;
}

.selectContainer {
	cursor: pointer;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding-bottom: 25px;
}
