$elementsColor: #2c7dce;

.wrapper {
  display: flex;
  height: 100vh;
  justify-content: center;
  flex-direction: column;
  align-content: center;

  button {
    height: 40px;
    font-size: 18px;
    border-radius: 40px;
    width: 78%;
    height: 51px;
  }
}

.inputContainer {
  padding: 24px 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  @media only screen and (max-width: 540px){
    margin: 0 70px;
  }
}

.loginContainer {
  width: 100%;
  padding-bottom: 23px;
}

.buttonsContainer {
  padding-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.content {
  display: flex;
  align-self: center;
  flex-direction: column;
  align-items: center;
  width: 34%;
  @media only screen and (max-width: 540px){
    padding: 0 70px;
    width: 100%;
  }
}

.title {
  width: 100%;
  padding: 0 0 38px 0;
  text-align: center;
  color: #2e3039;
  font-size: 30px;
  font-weight: 300;
  line-height: 41.05px;
}

.buttonText {
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  line-height: 21.03px;
}

.switchContainer {
  display: flex;
  padding: 33px 0 0 0;
  button {
    margin-top: 5px;
    height: 10px;
    width: 10px;

    &:after { 
      left: -1px;
      top: -5px;
      background-color: $elementsColor;
    }
  }  
}

.en {
  padding: 0 10px 0 0;
}

.ru {
  padding: 0 0 0 10px;
}

.errorContainer {
  padding: 30px;
  top: 85%;
  position: absolute;
}

.feedbackBtn {
  color: #3786D4;
  font-size: 15px;
  margin-top: 32px;
  
  &:hover {
    cursor: pointer;
  }
}