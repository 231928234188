$padding: 42px;
$padding-small: 20px;

.wrapper {
	height: 100vh;
	background-color: #ecf0f3;
  box-sizing: border-box;
  padding: 0 42px;
  color: #657d95;
  overflow: hidden;
  @media only screen and (max-width: 540px){
    padding: 0;
  }
}

.content {
  align-self: center;
  justify-content: space-around;
  height: 100vh;
	box-shadow: 0 2px 16px rgba(0, 0, 0, 0.25);
  background-color: white;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.content .link {
  padding: 33px 0 20px $padding;
  color: #1890ff;
  @media only screen and (max-width: 540px){
    padding: 20px 20px 20px 20px;
  }
}

.content div {
  padding-left: $padding;
  padding-right: $padding;
  @media only screen and (max-width: 540px){
    padding: 0 20px 20px 20px;
  }
}

.fileWrapper div {
  padding-left: $padding;
  padding-right: $padding;
  @media only screen and (max-width: 540px){
    padding: 0 20px;
  }
}

.date {
  padding: 13px 0 28px 30px;
  font-size: 15px;
  line-height: 24px;
  font-weight: 450;
}

.title {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  font-size: 30px;
  color: #2e3039;
  line-height: 41px;
  font-weight: 300;
}

.message {
  height: 60%;
  box-sizing: border-box;
  overflow: auto;
}

.content .fileWrapper {
  margin: 30px 0;
  padding: 12px 30px;
  display : flex;
  flex-direction: row;
  justify-content:  space-between;
  border-bottom: 1px solid #eff1f3;
  border-top: 1px solid #eff1f3;;
  @media only screen and (max-width: 540px){
    padding: $padding-small $padding-small $padding-small 0;
  }
}

.file {
  cursor: pointer;
}

.document {
  width: 50px;
  height: 50px;
}

.content .buttonsContainer {
  padding: 20px $padding $padding $padding;
  width: 100%;
  display: flex;
  justify-content: space-between;
  div {
    padding: 0;
  }
  @media only screen and (max-width: 540px){
    padding: $padding-small $padding-small $padding-small $padding-small;
    span{
      display: none;
    }
  }
}
