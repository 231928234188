@import '../../styles/main.module.scss';

$padding-left: 42px;
$padding-right: 42px;

.close {
	cursor: pointer;
	font-size: 20px;
	&:hover {
		color: dodgerblue;
	}
}

.table {
	display: block;
	overflow: auto;
	color: #657d95;
	font-size: 13px;
	font-weight: 400;
	line-height: 30px;
};

.lastBlock {
	margin-right: 40px;
}

.wrapper {
	min-width: 800px;
	overflow-x: scroll;
  flex-direction: column;
	box-sizing: border-box;;
};

.head {
	display: flex;
	padding: 0 40px 0 40px;
	justify-content: space-between;
	background-color: #f3f6fb;
	height: 40px;
	align-items: center;
	@media only screen and (max-width: 414px) {
		padding: 0 20px;
	}
}

.message {
	display: flex;
	min-width: 500px;
	justify-content: space-between;
	align-items: flex-start;
	margin: 0 40px 10px 40px;
	padding-top: 12px;
	@media only screen and (max-width: 540px) {
		margin: 0 20px 10px 20px;
	}
}

.border {
	border-bottom: 1px solid rgb(212, 212, 212);
	margin-bottom: 10px;
	margin-left: 20px;
}

.messageTitle {
	padding: 0 0 10px 0;
	font-size: 18px;
	line-height: 28px;
	font-weight: 300;
	color: #2e3039;
}

.firstBlock {
	width:40%;
}

.secondBlock {
	width:12%;
}

.thirdBlock {
	width:15%;
}

.fourthBlock {
	width:13%;
}

.text {
	line-height: 20px;
}

.loadMore {
	display: flex;
	justify-content: center;
	margin: 25px auto;
}

.fileWrapper div {
  padding-left: $padding-left;
  padding-right: $padding-right;

  @media only screen and (max-width: 540px){
    padding: 0 20px;
  }
}

.wrapper .fileWrapper {
  margin: 30px 0;
  padding: 12px 30px;
  display : flex;
  flex-direction: row;
  justify-content:  space-between;
  border-bottom: 1px solid #eff1f3;
	border-top: 1px solid #eff1f3;;

  @media only screen and (max-width: 540px){
    padding: 20px 20px 20px 0;
  }
}

.file {
  cursor: pointer;
}

.document {
  width: 50px;
  height: 50px;
}
