.wrapper {
  display: flex;
	padding: 0 54px;
	height: 100vh;
	background-color: #ecf0f3;
	box-sizing: border-box;
	@media only screen and (max-width: 540px) {
		padding: 0;
	}
}

.content {
	width: 100%;
	height: 100vh;
	box-shadow: 0 0 59px rgba(51, 59, 69, 0.15);
	background-color: white;
	display: flex;
	flex-direction: column;
}

.checkboxWrapper {
	justify-self:start;
	color: #2e3039;
	font-size: 30px;
	font-weight: 300;
	line-height: 1px;
	word-wrap: normal;
	padding-top: 10px;
	@media only screen and (max-width: 540px) {
		display: flex;
		grid-area: c;
		align-self: center;
		padding-top: 0;
		padding-bottom: 6px;
	}
	@media only screen and (max-width: 414px) {
		line-height: 23px;
		padding-bottom: 0;
	}
}

.title {
	font-size: 30px;
	font-weight: 300;
	line-height: 41px;
	color: #2e3039;
	@media only screen and (max-width: 540px) {
		font-size: 20px;
		position: relative;
	}
}

.head {
	display: flex;
	padding: 40px 39px 20px 39px;
  justify-content: space-between;
  align-content: space-between;
	@media only screen and (max-width: 540px) {
		display: flex;
		padding: 27px 19px 50px 19px;
	}
}

.logout {
	margin-right: 40px;
  color: #3c7bc4;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;

  span {
    text-align: end;
  }
}

.logoutContainer {
	margin-top: 11px;
	display: flex;
	justify-self: flex-end;
	color: #3c7bc4;
  font-size: 16px;
  font-weight: 400;
	line-height: 21px;
  flex-direction: row;
  justify-content: space-between;
	a {
		display: block;
		text-align: end;
		color: #3c7bc4;
	}
}

.messagesList {
	overflow: auto;
	a{
		text-decoration: none;
		color: inherit;
	}
}
