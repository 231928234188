$padding-wrapper: 64px;

$background-color: #ecf0f3;

$font-color: #657d95;

.wrapper {
  display: flex;
	padding: 0 $padding-wrapper;
	height: 100vh;
	background-color: $background-color;
	box-sizing: border-box;
	color: $font-color;
	@media only screen and (max-width: 540px) {
		padding: 0;
	}
}

.content {
	width: 100%;
	height: 100%;
	box-shadow: 0 2px 16px rgba(0, 0, 0, 0.25);
	background-color: white;
	display: flex;
	flex-direction: column;
}


.select {
	padding: 0 0 20px 38px;
	display: inline-block;
	min-width: 270px;
	@media only screen and (max-width: 540px) {
		padding: 0 20px 20px 20px;
	}
}

.checkbox{
	padding: 20px 20px;
	max-width: 270px;
	display: inline-block;
}

// .checkboxWrapper {
// 	display: flex;
// 	justify-content: space-between;
// 	align-items: center;
// }

.text {
	padding-left: 10px;
}

.title {
	font-size: 30px;
	color: #2e3039;
	font-weight: 300;
	line-height: 36px;
	display: inline-block;
	padding: 0 0 20px 38px;
	@media only screen and (max-width: 540px) {
		padding: 0 20px 20px 20px;
	}
}

.subTitle {
	@extend .title;
	padding: 24px 0 0 40px;
	font-size: 24px;
	@media only screen and (max-width: 540px) {
		padding: 20px 0 0 20px;
	}
}
