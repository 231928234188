$padding-wrapper: 64px;

$background-color: #ecf0f3;

$font-color: #657d95;

.wrapper {
  display: flex;
	padding: 0 $padding-wrapper;
	height: 100vh;
	background-color: $background-color;
	box-sizing: border-box;
	color: $font-color;
	@media only screen and (max-width: 540px) {
		padding: 0;
	}
}

.content {
	width: 100%;
	height: 100%;
	box-shadow: 0 2px 16px rgba(0, 0, 0, 0.25);
	background-color: white;
	display: flex;
	flex-direction: column;
}

.title {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	color: $font-color;
	font-weight: 300;
	line-height: 36px;
	margin-top: 70px;
	padding: 0 40px 20px 38px;
	@media only screen and (max-width: 540px) {
		padding: 0 20px 20px 20px;
	}
}

.titleWrapper {
	cursor: pointer;

	&:hover {
		color: #40a9ff;
	}
}

.modalContent {
	max-height: 600px;
}

.sudentsContent {
	overflow: auto;
	max-height: 300px;
	display: grid;
	grid-template-columns: 300px 300px;
	grid-gap: 10px 51px;
	color: $font-color;
}

.modalTitle {
	font-size: 18px;
	font-weight: 300;
	line-height: 36px;
}

.modalFormLabel {
	color: $font-color;
	line-height: 36px;
	font-size: 16px;
}

.studentsLabelWrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: $font-color;
	line-height: 36px;
	padding-bottom: 10px;
}

.buttonContainer{
	display: flex;
	justify-content: flex-end;
  padding: 21px 0 0 0;
}

.table {
	display: block;
	overflow: auto;
	color: #657d95;
	font-size: 13px;
	font-weight: 400;
	line-height: 30px;
};

.tableWrapper {
	min-width: 800px;
	overflow-x: scroll;
  flex-direction: column;
	box-sizing: border-box;;
};

.head {
	display: flex;
	padding: 0 40px 0 40px;
	justify-content: space-between;
	background-color: #f3f6fb;
	height: 40px;
	align-items: center;
	@media only screen and (max-width: 414px) {
		padding: 0 20px;
	}
}

.message {
	display: flex;
	min-width: 500px;
	justify-content: space-between;
	align-items: center;
	padding: 20px 40px 20px 40px;
	@media only screen and (max-width: 540px) {
		margin: 0 20px 0 20px;
	}

	// &:hover {
	// 	background-color: #f3f6fb;
	// }
}

.border {
	border-bottom: 1px solid rgb(212, 212, 212);
}

.messageTitle {
	font-size: 20px;
	line-height: 28px;
	font-weight: 300;
	color: #2e3039;
}

.icon {
	cursor: pointer;
	font-size: 24px;
	line-height: 28px;
	font-weight: 300;
	color: #2e3039;
	&:hover {
		color: #40a9ff;
	}
}

.firstBlock {
	width:15%;
}

.secondBlock {
	width:40%;
}

.thirdBlock {
	width:15%;
}

.text {
	line-height: 20px;
}

.rightCheckbox {
  // padding-right: 20px;
  display: flex;
  justify-content: flex-end;
  // padding-bottom: 17px;
  label {
    display: flex;
    align-items: center;
  }
  @media only screen and (max-width: 900px) {
    justify-content: flex-start;
	}
}

.rightCheckbox_text {
  padding-right: 10px;
}

.sudentsWrapper {
	label {
		display: flex;
		justify-content: space-between;
    align-items: center;
  }
}
