.title {
  font-size: 18px;
  margin-bottom: 39px;
}

.inputWrapper {
  margin-bottom: 16px;
}

.actionsWrapper {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
}

.target {
  display: flex;
  justify-content: space-between;

  & > * {
    font-size: 13px !important;
  }
}

.link {
  color: #317fcb;
}
