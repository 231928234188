@import './mailingBlock.module.scss';

.selectContainer {

  & .ant-select-selection {
		border: $input-border;
  }

	div {
		div {
			height: $input-height;
			border-radius: $border-radius;

		}
	}
}