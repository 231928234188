.wrapper {
  color: red;
  padding: 20px;
  background-color: white;
  box-shadow: 0 0 59px rgba(51, 59, 69, 0.15);
}

.iconContainer {
  position: absolute;
  top: 3%;
  left: 95%;
}
