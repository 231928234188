body {
  margin: 0;
  height: 100vh;
  /* font-family: -apple-system, BlinkMacSystemFont, "Roboto"; */
  font-family: "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  color: #657d95;
}

.ant-checkbox-wrapper {
  line-height: 1.5;
}
