.wrapper {
  padding: 17px 0 0 0;
  width: 100%;

}

form {
  textArea {
    width: 100%;
    box-sizing: border-box;
    border-radius: 10px;
  }
}

.icon {
  cursor: pointer;
  position: relative;
  width: 20px;
  height: 20px;
  fill: gray;
  left: 10px;
  top: 165px;
  z-index: 2;
  float: left;
}

.file {
  opacity: 0;
  width: 1%;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.buttonContainer{
  padding: 21px 0 0 0;
}
