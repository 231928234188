.messagesList {
	overflow: auto;
	a{
		text-decoration: none;
		color: inherit;
	}
}

.messageHeader {
	display: flex;
	justify-content: space-between;
	padding-bottom: 10px;
	color: #2e3039;
	font-size: 18px;
	font-weight: 300;
	line-height: 41px;

	@media only screen and (max-width: 540px) {
		line-height: 19px;
	}
}

.message {
	padding: 0 39px 11px 40px;
	border-top: 1px solid #eff1f3;
	border-bottom: 1px solid #eff1f3;
	font-size: 13px;
	font-weight: 400;
	line-height: 41px;
	cursor: pointer;
	pointer-events: auto;

	&:hover { 
		background-color: #488dd1;
		color: white;
		cursor: pointer;

		.date {
			color: white;
		}
		
		.messageHeader {
			color: white;
		}
	 }

	 @media only screen and (max-width: 540px) {
		padding: 20px;
	} 
}

.messagesNotFoundWrapper {
	display: flex;
	justify-content: center;
}

.messagesNotFound {
	padding-top: 10%;
}

.date {
	color: #657d95;
	font-size: 13px;
	font-weight: 500;
	line-height: 24px;
	display: flex;
	align-self: center;

	@media only screen and (max-width: 540px) {
		line-height: 21px;
	} 
}

.loadMore {
	display: flex;
	justify-self: center;
	margin: 25px auto;
}

.messageText {
	line-height: 20px;
}

.unreadMessage {
	background-color: #e7edf7;
}