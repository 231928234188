.logout {
  color: #3c7bc4;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;

  span {
    text-align: end;
  }
}

.icon {
  padding: 0 0 0 10px;
  width: 25px;
  height: 25px
}
