$padding: 42px;
$padding-small: 20px;

.wrapper {
  display: flex;
  padding: 0 54px;
  min-height: 100vh;
  background-color: #ecf0f3;
  box-sizing: border-box;

  @media only screen and (max-width: 540px) {
    padding: 0;
  }
}

.content {
  width: 100%;
  min-height: 100vh;
  box-shadow: 0 0 59px rgba(51, 59, 69, 0.15);
  background-color: white;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 30px;
  font-weight: 300;
  line-height: 41px;
  color: #2e3039;

  @media only screen and (max-width: 540px) {
    font-size: 20px;
    position: relative;
  }
}

.head {
  display: flex;
  flex-direction: column;
  padding: 40px 39px 10px 39px;

  @media only screen and (max-width: 540px) {
    display: flex;
    padding: 27px 19px 5px 19px;
  }
}

.tableWrapper {
  padding: 0px 10px 10px;

  @media only screen and (min-width: 540px) {
    padding: 0px 40px 40px;
  }
}

.paginationWrapper {
  display: flex;
  flex-direction: row-reverse;
  padding: 10px 10px 60px 0px;

  @media only screen and (min-width: 540px) {
    padding: 0px 60px 60px 0px;
  }
}

.tableHeader {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.totalDebtTitle {
  font-size: 15px;
  color: #e20212;
}

.totalDebtTitleNoTax {
  font-size: 15px;
}

.totalDebtButton {
  font-size: 15px;
  color: #e20212;
  padding: 10px 7px 7px 5px;
  border: 1px solid #e20212;
  line-height: 1;
  border-radius: 7px;
  background-color: #f3f6fb;
}

.reloadPaymentsButton {
  height: 30px;
  padding: 8px;
  width: 30px;
  background-color: #f3f6fb;
  border-radius: 7px;

  &:hover {
    cursor: pointer;
  }
}

.th {
  white-space: break-spaces;
  margin-right: 20px;
}

.thPaymentName {
  min-width: 240px;
}

// .thServiceCost {
//   width: 75px;
// }

// .thAmountOfInterest {
//   width: 75px;
// }

// .thExpiredDays {
//   width: 110px;
// }

// .thTotalPayable {
//   width: 95px;
// }

// .thPaymentCrediting {
//   width: 140px;
// }

.penaltyText {
  color: #e20212;
}

.payBtn {
  background-color: #2c7dce;
  padding: 4px 7px;
  color: white;
  border-radius: 14px;
  border: none;
  outline: none;
  cursor: pointer;
}

.mobilePaymentCards {
  display: none;
  @media only screen and (max-width: 540px) {
    display: block;
  }
}

.paymentTable {
  display: block;

  @media only screen and (max-width: 540px) {
    display: none;
  }

  table {
    width: initial !important;
  }
}
