.ant-btn-primary {
  color: #fff;
  background-color: #2c7dce;
  border-color: #2c7dce;
}

.messageButtonsContainer .ant-btn-round.ant-btn-lg {
  height: 44px;
  padding: 0 20px;
  font-size: 16px;
  margin-bottom: 10px;
  border-radius: 40px;
  @media only screen and (max-width: 540px){
    width: 70px !important;
  }
}