.modal {
  width: 100%;
  max-width: 100%;
  top: 0px;
  margin: 0;
  min-height: 100vh;
  padding-bottom: 0px;

  & > div:nth-child(2) {
    padding-top: 0px;
    height: 100vh;
  }
}

@media only screen and (min-width: 540px) {
  .modal {
    max-width: 453px;
    margin: 0 auto;
    top: 100px;

    & > div:nth-child(2) {
      padding: 20px 12px !important;
      height: auto;
    }
  }
}
