.sendForm__inputContainer .ant-input {
  border: 1px solid #c9d1d8;
  width: 100%;
  height: 40px;
  color: #657d95;
  font-size: 15px;
  font-weight: 400;
  line-height: 21.02px;
  border-radius: 7px;
}

.sendForm__textareaContainer .ant-input {
  border: 1px solid #c9d1d8;
  width: 100%;
  color: #657d95;
  font-size: 15px;
  font-weight: 400;
  line-height: 21.02px;
  border-radius: 7px;
}

.buttonWrapper {
  .ant-btn-primary {
    color: #fff;
    background-color: #2c7dce;
    border-color: #2c7dce;
  }
  .ant-btn-round.ant-btn-lg {
    height: 44px;
  }
}