@import "../../styles/main.module.scss";

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #f3f6fb;
  box-sizing: border-box;
  padding: 0px 40px 0px 0;
  height: 60px;
  a {
    color: inherit;
  }
  @media only screen and (max-width: 705px) {
    padding: 10px 10px 15px 10px;
    flex-direction: column;
    align-items: center;
    height: auto;
  }
}

.wrapper2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #f3f6fb;
  box-sizing: border-box;
  padding: 0px 40px 0px 0;
  height: auto;
  align-items: center;
  a {
    color: inherit;
  }
  @media only screen and (max-width: 1200px) {
    padding: 10px 10px 15px 10px;
    flex-direction: column;
    align-items: center;
    height: 320px;
    min-height: 320px;
  }
}

.linksBlock {
  display: flex;
  @media only screen and (max-width: 705px) {
    flex-direction: column;
    align-items: center;
  }
}

.link {
  margin-left: 40px;
  padding-top: 16px;
  padding-bottom: 16px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  border-bottom: 3px solid #f3f6fb;
  @media only screen and (max-width: 705px) {
    margin: 0 10px 0 0;
    padding: 0;
  }
}

.selected {
  border-bottom: 3px solid #488dd1;
}

.feedback {
  height: 24px;
  color: #3c7bc4;
  font-size: 13px;
  font-weight: 400;
  width: fit-content;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  border: 1px solid #3c7bc4;
  padding: 3px 9px;
  margin: 0 5px;

  border-radius: 12px;
  transition: 0.2s ease-out;

  span {
    text-align: end;
    white-space: nowrap;
  }

  div {
    height: 15px;
    width: 15px;
    margin-right: 4px;
    background-image: url("../../images/feedback-blue.svg");
    background-size: cover;
  }

  &:hover {
    color: white;
    background-color: #3c7bc4;

    div {
      background-image: url("../../images/feedback-white.svg");
    }
	}
	
	@media only screen and (min-width: 540px) {
    margin-right: 15px;
  }
}

.actions {
  display: block;
  margin-top: 25px;

  @media only screen and (min-width: 705px) {
    display: flex;
    align-items: center;
    margin-top: 0;
  }
}
