.round {
  position: static;
}

.round label {
  background-color: #cad0df;
  border-radius: 30px;
  cursor: pointer;
  height: 50px;
  position: static;
  width: 80px;
  content: "";
  padding: 5px;
  color: white;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  opacity: 0;
  position: static;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  display: none;
}

.round input[type="checkbox"]:checked + label {
  background-color: #2381df;
}

.round input[type="checkbox"]:checked + label:after {
  
}