.text {
  padding-bottom: 14px;
  font-size: 15px;
  font-weight: 400;
  line-height: 30px;
}

.groupsBlock {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 10px 0; 
}

.groupsContainer {
  display: block;
  width: 300px;

  &:first-child {
    margin-left: 0px;
  }

  &:nth-child(7n) {
    margin-right: 0px;
  }

  @media only screen and (max-width: 540px) {
    width: 250px;
  }

  @media only screen and (max-width: 414px) {
    width: 200px;
  }
}
