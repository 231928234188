$padding-top: 53px;
$padding-right: 40px;
$padding-bottom: 72px;
$padding-left: 37px;

$border-row: 1px solid #eff1f3;

$first-color: #ffffff;
$second-color: #f3f6fa;

$border-radius: 7px;

.wrapper {
  display: flex;
  flex-direction: column;
  padding: $padding-top 20px $padding-bottom 0;
  margin-left: 40px;
  height: auto;
  overflow: auto;
  @media only screen and (max-width: 900px) {
    padding: 13px 20px 20px 20px;
    margin: 0;
    overflow: auto;
	}
}

.spinWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.tableWrapper {
  display: flex;
  flex-direction: column;
  height: auto;
  overflow: auto;
}

table {
  width: 100%;
  white-space: nowrap;
  border-radius: $border-radius;
  border: 1px solid #eff1f3;
  tr{
    height: 47px;
    &:nth-child(odd){
      background-color: $second-color;
    }
    th {
      padding: 15px 14px;
    }
  }
}

.rightCheckbox {
  padding-right: 20px;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 17px;
  label {
    display: flex;
    align-items: center;
  }
  @media only screen and (max-width: 900px) {
    justify-content: flex-start;
	}
}

.rightCheckbox_text {
  padding-right: 10px;
}
