.group {
  margin:  0 6px 8px 0;
}

.group {
  div {
    div {
      label {
        width: 40px;
        height: 23px;
        border-radius: 50px;
        background-color: #c4cadb;
        color: #fefefe;
        font-size: 12px;
        font-weight: 400;
        padding: 5px 7px 5px 7px;
      }
    }
  }
}

.group:nth-child(7n) {
  padding-right: 0px;
}
